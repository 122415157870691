import { gql } from "@apollo/client";

export const CHANGE_LANGUAGE = gql`
  mutation($language: String!) {
    changeLanguage(language: $language) {
      success
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      success
      user {
        id
        firstName
        lastName
        emailAddress
        phoneNumber
        isQualifiedTeacher
        levelOfEducation
        isSuperAdmin
        isShortNoticeReady
        lastPoliceRegister
        isLookingForAssignments
        wantedSchoolTypes {
          id
        }
        wantedSubjects {
          id
        }
        wantedCities {
          id
        }
      }
    }
  }
`;

export const REQUEST_LOGIN_LINK = gql`
  mutation(
    $email: String!
    $urlTemplate: String!
    $registrationData: UpdateUserInput!
  ) {
    requestLoginLink(
      email: $email
      urlTemplate: $urlTemplate
      registrationData: $registrationData
    ) {
      success
      message
    }
  }
`;

export const CREATE_JOB = gql`
  mutation CreateJob($input: JobInput!) {
    createJob(input: $input) {
      success
      message
    }
  }
`;

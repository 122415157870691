import React from "react";
import { Link } from "@reach/router";

export default function ProofsOfConcept() {
  return (
    <>
      <h1>Proofs of Concept</h1>
      <ul>
        <li>
          <Link to="/poc/jobs">Jobb - ett testskott</Link>
        </li>
      </ul>
    </>
  );
}

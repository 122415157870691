import React from "react";
import { useTranslation } from "react-i18next";

export default function Heading(trans) {
  const { t } = useTranslation();
  return (
    <header className="bg-white">
      <div className="mx-auto px-4 pt-4 lg:px-8 lg:pt-8">
        <h1 className="text-2xl md:text-3xl font-bold leading-tight text-gray-700">
          {t(`${trans.transkey}`)}
        </h1>
      </div>
    </header>
  );
}

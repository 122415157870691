import React from "react";
import { Link } from "@reach/router";
import { useQuery } from "@apollo/client";
import { ADMIN_USER } from "../../graphql/queries";
import dotProp from "dot-prop";
import sortBy from "lodash.sortby";
import { UserSwitch } from "../UserSwitch";
import { useTranslation } from "react-i18next";
import DatePickerField from "../Profile/DatePickerField";
import { UPDATE_USER } from "../../graphql/mutations";

const superAdminTrue = (id) => {
  return {
    variables: {
      input: {
        id,
        isSuperAdmin: true,
      },
    },
  };
};
const superAdminFalse = (id) => {
  return {
    variables: {
      input: {
        id,
        isSuperAdmin: false,
      },
    },
  };
};
const isLookingForAssignmentsTrue = (id) => {
  return {
    variables: {
      input: {
        id,
        isLookingForAssignments: true,
      },
    },
  };
};
const isLookingForAssignmentsFalse = (id) => {
  return {
    variables: {
      input: {
        id,
        isLookingForAssignments: false,
      },
    },
  };
};
const isShortNoticeReadyFalse = (id) => {
  return {
    variables: {
      input: {
        id,
        isShortNoticeReady: false,
      },
    },
  };
};
const isShortNoticeReadyTrue = (id) => {
  return {
    variables: {
      input: {
        id,
        isShortNoticeReady: true,
      },
    },
  };
};

export default function AdminUser({ userId }) {
  const { data } = useQuery(ADMIN_USER, {
    variables: { userId },
  });
  const { t } = useTranslation();
  const user = dotProp.get(data, "user", null);
  const wantedCities = user ? sortBy(user.wantedCities, "name") : null;
  const wantedSubjects = user ? sortBy(user.wantedSubjects, "name") : null;
  const wantedSchoolTypes = user
    ? sortBy(user.wantedSchoolTypes, "name")
    : null;

  return (
    <div className="m-4">
      <h1 className="text-lg">
        <Link to="/admin" className="text-blue-700">
          Admin
        </Link>
        {" > "}
        <Link to="/admin/users" className="text-blue-700">
          Users
        </Link>
        {" > "}
        <span>{user ? user.name : ""}</span>
      </h1>
      {user && (
        <div className="flex flex-col md:flex-row mt-2">
          {/* Left pane */}
          <div className="p-2">
            <div className="h-64 w-64">
              {user.profilePicture !== null ? (
                <img
                  className="rounded-full"
                  src={user.profilePicture}
                  alt="Profile pic"
                />
              ) : (
                <div className="w-full h-full bg-gray-200 rounded-full flex flex-col justify-center">
                  <div className="flex flex-row justify-center text-gray-100">
                    No profile pic
                  </div>
                </div>
              )}
            </div>
            <div className="mt-4 p-2 border">
              <div className="font-bold">{user.name}</div>
              <div className="text-gray-400">{user.emailAddress}</div>
              {user.phoneNumber ? (
                <div className="text-gray-400">{user.phoneNumber}</div>
              ) : null}
              <div className="mt-4">
                <div>
                  Education level:{" "}
                  <a
                    href="https://en.wikipedia.org/wiki/International_Standard_Classification_of_Education"
                    className="text-blue-700"
                  >
                    {user.levelOfEducation}
                  </a>
                </div>
                <div>Qualified: {user.isQualifiedTeacher ? "Yes" : "No"}</div>
                <div>Subtitute: {user.isSubstituteTeacher ? "Yes" : "No"}</div>
                <div className={"flex w-full md:w-full justify-between my-1"}>
                  <div className={"mr-4"}>Superadmin: </div>
                  <UserSwitch
                    fieldName={"isSuperAdmin"}
                    initialState={user.isSuperAdmin}
                    off={superAdminFalse(user.id)}
                    on={superAdminTrue(user.id)}
                  />
                </div>
                <div className={"flex w-full md:w-full justify-between my-1"}>
                  <div className={"mr-4"}>
                    {t`components.admin_user.is_looking_for_assignments`}{" "}
                  </div>
                  <UserSwitch
                    initialState={user.isLookingForAssignments}
                    off={isLookingForAssignmentsFalse(user.id)}
                    on={isLookingForAssignmentsTrue(user.id)}
                    fieldName={"isLookingForAssignments"}
                  />
                </div>
                <div className={"flex w-full md:w-full justify-between my-1"}>
                  <div className={"mr-4"}>
                    {t`components.admin_user.is_short_notice_ready`}{" "}
                  </div>
                  <UserSwitch
                    initialState={user.isShortNoticeReady}
                    off={isShortNoticeReadyFalse(user.id)}
                    on={isShortNoticeReadyTrue(user.id)}
                    fieldName={"isShortNoticeReady"}
                  />
                </div>
                <div className={"flex w-full md:w-full justify-between my-1"}>
                  <div className={"mr-4"}>
                    {t`profile_page.lastPoliceRegister`}{" "}
                  </div>
                  <DatePickerField
                    userId={user.id}
                    initialDate={user.lastPoliceRegister}
                    mutation={UPDATE_USER}
                    placeholder={t`profile_page.noPoliceRegister`}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Right pane */}
          <div className="mt-2 md:ml-2 md:mt-0 p-2">
            <h2 className="text-gray-400">Cities</h2>
            <div className="pl-2 flex flex-row flex-wrap">
              {wantedCities.map((city) => (
                <div className=" bg-gray-100 p-1 mr-1 rounded" key={city.id}>
                  {city.name}
                </div>
              ))}
            </div>
            <h2 className="text-gray-400">School types</h2>
            <div className="pl-2 flex flex-row flex-wrap">
              {wantedSchoolTypes.map((type) => (
                <div className=" bg-gray-100 p-1 mr-1 rounded" key={type.id}>
                  {type.name}
                </div>
              ))}
            </div>
            <h2 className="text-gray-400">Subjects</h2>
            <div className="pl-2 flex flex-row flex-wrap">
              {wantedSubjects.map((subject) => (
                <div className=" bg-gray-100 p-1 mr-1 rounded" key={subject.id}>
                  {subject.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import React from "react";
import Heading from "./Heading";
import CorporateTxt from "./CorporateTxt";

import klassrum from "../img/klassrum.jpg";

export default function About() {
  return (
    <>
      <Heading transkey="about_page.heading" />
      <CorporateTxt />
      <div className="bg-cover" style={{ backgroundImage: `url(${klassrum})` }}>
        <div className="sm:flex sm:flex-row justify-center font-noto p-4 sm:px-20 sm:py-32 text-white">
          <div className="px-12 text-center sm:w-1/3">
            <div className="text-4xl">1.</div>
            <div className="text-xl">Uppdrag på dina villkor</div>
            <div>Du väljer själv när, var och hur du vill arbeta.</div>
          </div>
          <div className="px-12 text-center sm:w-1/3">
            <div className="text-4xl">2.</div>
            <div className="text-xl">
              Ta vara på din kunskap som undervisare
            </div>
            <div>Var med och undervisa Sveriges barn och ungdomar</div>
          </div>
          <div className="px-12 text-center sm:w-1/3">
            <div className="text-4xl">3.</div>
            <div className="text-xl">Inga mellanhänder</div>
            <div>Du arbetar direkt för skolan, inga dyra mellanhänder</div>
          </div>
        </div>
      </div>
    </>
  );
}

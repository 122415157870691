import React, { useEffect } from "react";
import { useMutation, gql } from "@apollo/client";
import { Redirect, Link } from "@reach/router";

const VERIFY_LOGIN_CODE = gql`
  mutation($code: String!) {
    verifyLoginCode(code: $code) {
      success
      message
    }
  }
`;

export default function VerifyLogin({ code }) {
  const [verifyLoginCode, { loading, data, error }] = useMutation(
    VERIFY_LOGIN_CODE
  );
  useEffect(() => {
    if (code) {
      verifyLoginCode({
        refetchQueries: ["Session"],
        variables: { code },
      });
    }
  }, [code, verifyLoginCode]);
  if (loading || !data) {
    return null;
  }
  if (error) {
    return (
      <div>
        <div>Something went wrong with code {code}. 🤔</div>
        <pre>{JSON.stringify({ loading, data, error }, null, 2)}</pre>
      </div>
    );
  }
  const message = data.verifyLoginCode.message;
  // TODO: Use <Trans> to translate Please click ...
  // https://react.i18next.com/latest/trans-component
  if (!data.verifyLoginCode.success) {
    return (
      <>
        <p>{message}</p>
        <p>
          Please cick <Link to="/">here</Link> to try again.
        </p>
      </>
    );
  }
  return <Redirect noThrow to="/" />;
}

import React from "react";
import { Link } from "@reach/router";
import { useQueryParam, StringParam, ArrayParam } from "use-query-params";
import { useQuery } from "@apollo/client";
import { ADMIN_USERS, STATIC_FORM_DATA } from "../../graphql/queries";
import dotProp from "dot-prop";

function download(filename, text) {
  const element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export default function AdminUsers() {
  const [cityId, setCityId] = useQueryParam("cityId", StringParam);
  const [schoolTypeId, setSchoolTypeId] = useQueryParam(
    "schoolTypeId",
    StringParam
  );
  const [isSubstituteTeacher, setIsSubstituteTeacher] = useQueryParam(
    "isSubstituteTeacher",
    StringParam
  );
  const [requireSubject, setRequireSubject] = useQueryParam(
    "requireSubject",
    StringParam
  );
  const [requiredSubjectIds, setRequiredSubjectIds] = useQueryParam(
    "requiredSubjectIds",
    ArrayParam
  );
  const { data: statisFormData } = useQuery(STATIC_FORM_DATA, {
    variables: { countryCode: "se" },
  });

  const searchParams = {
    variables: {
      isSubstituteTeacher:
        isSubstituteTeacher === undefined
          ? null
          : { yes: true, no: false }[isSubstituteTeacher],
      requiredSchoolTypeId: schoolTypeId,
      requiredCityId: cityId,
      requiredSubjectIds: requireSubject === "yes" ? requiredSubjectIds : [],
    },
  };
  const { data: adminUsersData, loading: loadingUsersData } = useQuery(
    ADMIN_USERS,
    searchParams
  );

  const cities = dotProp.get(statisFormData, "cities", null);
  const schoolTypes = dotProp.get(statisFormData, "schoolTypes", null);
  const subjects = dotProp.get(statisFormData, "subjects", null);
  const users = dotProp.get(adminUsersData, "users", []);

  const handleChange = (event) => {
    const { target } = event;
    const { value, name } = target;
    if (name === "isSubstituteTeacher") {
      setIsSubstituteTeacher(value === "" ? undefined : value);
    } else if (name === "schoolType") {
      setSchoolTypeId(value === "" ? undefined : value);
    } else if (name === "city") {
      setCityId(value === "" ? undefined : value);
    } else if (name === "requireSubject") {
      setRequireSubject(value === "" ? undefined : value);
    }
  };

  const handleRequiredSubjectChange = (event) => {
    const { target } = event;
    const { checked, name } = target;
    if (checked && requiredSubjectIds === undefined) {
      setRequiredSubjectIds([name]);
    } else if (
      !checked &&
      (requiredSubjectIds === undefined || requiredSubjectIds.length === 1)
    ) {
      setRequiredSubjectIds(undefined);
    } else if (checked && !requiredSubjectIds.includes(name)) {
      setRequiredSubjectIds([...requiredSubjectIds, name]);
    } else if (!checked && requiredSubjectIds.includes(name)) {
      setRequiredSubjectIds(requiredSubjectIds.filter((id) => id !== name));
    }
  };

  const handleExport = () => {
    const lines = [];
    for (const { firstName, lastName, emailAddress } of users) {
      lines.push(`${firstName},${lastName},${emailAddress}`);
    }
    const text = "First name, Last name, Email address\n" + lines.join("\n");
    download("undervisare_users.csv", text);
  };

  return (
    <div className="m-4">
      <div className="flex flex-row flex-wrap justify-between">
        <h1 className="text-lg">
          <Link to="/admin" className="text-blue-700">
            Admin
          </Link>
          {" > "}
          <span>Users</span>
        </h1>
        {users.length > 0 && (
          <button
            onClick={handleExport}
            className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white px-1 border border-blue-500 hover:border-transparent rounded"
          >
            Export as CSV
          </button>
        )}
      </div>

      <div className="mt-2 p-2 bg-gray-100 flex flex-row flex-wrap">
        <div className="m-2 ">
          <div className="p-2 bg-white">
            <div className="text-gray-500 ">Substitute teacher</div>
            <select
              name="isSubstituteTeacher"
              id="isSubstituteTeacher"
              onChange={handleChange}
              value={isSubstituteTeacher ? isSubstituteTeacher : ""}
            >
              <option value="">Any</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
        </div>
        <div className="m-2 ">
          <div className="p-2 bg-white">
            <div className="text-gray-500 ">School type</div>
            <select
              name="schoolType"
              id="schoolType"
              onChange={handleChange}
              value={schoolTypeId ? schoolTypeId : ""}
            >
              <option value="">Any</option>
              {schoolTypes &&
                schoolTypes.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="m-2 ">
          <div className="p-2 bg-white">
            <div className="text-gray-500 ">City</div>
            <select
              name="city"
              id="city"
              onChange={handleChange}
              value={cityId ? cityId : ""}
            >
              <option value="">Any</option>
              {cities &&
                cities.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="m-2 ">
          <div className="p-2 bg-white">
            <div className="text-gray-500 ">Required subjects</div>
            <select
              name="requireSubject"
              id="requireSubject"
              onChange={handleChange}
              value={requireSubject ? requireSubject : ""}
            >
              <option value="">None</option>
              <option value="yes">The ones checked below</option>
            </select>
          </div>
        </div>
      </div>
      {requireSubject === "yes" && (
        <div className="p-2 bg-gray-100 flex flex-row flex-wrap">
          {subjects &&
            subjects.map((subject) => (
              <div className=" bg-white p-1 mr-2 mb-2 rounded" key={subject.id}>
                <input
                  type="checkbox"
                  onChange={handleRequiredSubjectChange}
                  name={subject.id}
                  checked={
                    requiredSubjectIds &&
                    requiredSubjectIds.includes(subject.id)
                  }
                />
                <span className="pl-1">{subject.name}</span>
              </div>
            ))}
        </div>
      )}
      <div className="mt-2 p-2">
        {loadingUsersData && <div>Loading ...</div>}
        {!loadingUsersData && users.length === 0 && <div>No users found</div>}
        {!loadingUsersData && users.length > 0 && (
          <div>
            <div>{users.length} users found</div>
            <ul className="mt-2">
              {users.map((user) => (
                <li key={user.id}>
                  <Link to={user.id} className="text-blue-700">
                    {user.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

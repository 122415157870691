import React from "react";
import Heading from "./Heading";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { JOBS } from "../graphql/queries";
import NotFound from "./NotFound";
import dotProp from "dot-prop";
import useSession from "graphql/custom-hooks/useSession";
import { Link } from "@reach/router";

export default function JobList() {
  const [{ user }] = useSession();
  const { data, loading, error } = useQuery(JOBS);
  const { t } = useTranslation();

  if (loading) return null;
  if (error) return null;
  if (!data) return <NotFound />;

  const { jobs } = data;

  if (!user) return <NotFound />;
  return (
    <>
      <div className="p-2 text-sm text-gray-700" />
      <div className="shadow-lg rounded-lg md:w-4/5 mx-auto mb-10">
        <Heading transkey="joblist_page.content" />

        <div className="grid grid-cols-3 md:grid-cols-7 gap-0 auto-cols-min text-sm sm:text-base">
          <div className={"p-4 border-b col-span-1 md:col-span-2"}>
            {t("components.job_list.title")}
          </div>
          <div className={"p-4 border-b"}>
            {t("components.job_list.starting_date")}
          </div>
          <div className={"p-4 border-b"}>
            {t("components.job_list.end_date")}
          </div>
          <div className={"col-span-3 md:block hidden p-4 border-b"}>
            {t("components.job_list.desc")}
          </div>

          {jobs.map((job) => (
            <Job
              key={job.id}
              title={job.title}
              desc={job.description}
              school={dotProp.get(job, "school.name", null)}
              startdate={job.startDate}
              enddate={job.endDate}
              id={job.id}
            />
          ))}
        </div>
      </div>
    </>
  );
}

function Job({ id, /*key,*/ desc, title, /*school,*/ startdate, enddate }) {
  const { t } = useTranslation();
  return (
    <Link to={"/uppdrag/" + id} className="contents group">
      <div className="font-semibold align-middle justify-center md:col-span-2 p-4 md:border-b cursor-pointer group-hover:bg-gray-100">
        {title}
      </div>
      <div className="align-middle place-self-auto p-4 md:border-b  group-hover:bg-gray-100">
        {startdate}
      </div>
      <div className="align-middle p-4 md:border-b group-hover:bg-gray-100">
        {enddate}
      </div>
      <div className="align-middle block md:hidden col-span-1 p-4 border-b  group-hover:bg-gray-100">
        {t("components.job_list.desc")}
      </div>
      <div className="align-middle col-span-2 md:col-span-3 p-4 border-b  group-hover:bg-gray-100 ">
        <div className="line-clamp-4 overflow-ellipsis">{desc}</div>
      </div>
    </Link>
  );
}

import React from "react";
import { useQuery } from "@apollo/client";
import { ALL_SCHOOLS_VISITOR } from "../graphql/queries";
import Heading from "./Heading";
import NotFound from "./NotFound";

export default function SchoolList() {
  const { data, loading, error } = useQuery(ALL_SCHOOLS_VISITOR, {
    variables: { offset: 0, limit: 100 },
  });

  if (!data) return <NotFound />;
  if (loading) return null;
  if (error) return null;

  const { schools } = data;

  return (
    <>
      <Heading transkey="common.schools" />
      <div className="p-2 text-sm text-gray-700 lg:px-8">
        {schools.map((school) => (
          <School key={school.id} school={school} />
        ))}
      </div>
    </>
  );
}
function School({ school }) {
  return (
    <div className="py-2 my-4 lg:flex-row lg:flex items-center sm:text-base shadow">
      <div className="flex flex-row items-center">
        <div className="font-bold px-2">
          {school.name}, {school.city.name}
        </div>
      </div>
    </div>
  );
}

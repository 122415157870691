import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import { useQuery, useMutation } from "@apollo/client";
import { STATIC_FORM_DATA } from "../graphql/queries";
import { REQUEST_LOGIN_LINK } from "../graphql/mutations";
import { navigate } from "@reach/router";
import SearchSelect from "./Profile/SearchSelect";

const SignupForm = () => {
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    cities: [],
    school_types: [],
    subjects: [],
    education: "",
    license: false,
    terms: false,
  };

  const { t } = useTranslation();
  const [requestLoginLink] = useMutation(REQUEST_LOGIN_LINK);

  const { data, loading, error } = useQuery(STATIC_FORM_DATA);

  if (!data) return null;
  if (loading) return null;
  if (error) return null;
  const { cities, subjects, schoolTypes } = data;

  return (
    <div className="w-full max-x-xs">
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          firstname: Yup.string()
            .max(20, t("signupform.validation.max_text"))
            .required(t("signupform.validation.req_fname")),
          lastname: Yup.string()
            .max(20, t("signupform.validation.max_text"))
            .required(t("signupform.validation.req_lname")),
          email: Yup.string()
            .email(t("signupform.validation.val_mail"))
            .required(t("signupform.validation.req_mail")),
          cities: Yup.string().required(t("signupform.validation.cities")),
          school_types: Yup.string().required(
            t("signupform.validation.school_types")
          ),
          subjects: Yup.string().required(t("signupform.validation.subjects")),
          education: Yup.string().required(
            t("signupform.validation.education")
          ),
          terms: Yup.boolean()
            .required(t("signupform.validation.accept"))
            .oneOf([true], t("signupform.validation.accept")),
        })}
        onSubmit={(values, { setSubmitting }) => {
          const urlTemplate =
            window.location.protocol +
            "//" +
            window.location.host +
            "/verifylogin/{{code}}";
          const registrationData = {
            id: values.id,
            firstName: values.firstname,
            lastName: values.lastname,
            isQualifiedTeacher: values.license,
            isSubstituteTeacher: true,
            levelOfEducation: parseInt(values.education),
            wantedSchoolTypeIds: values.school_types,
            wantedCityIds: values.cities,
            wantedSubjectIds: values.subjects,
          };
          requestLoginLink({
            variables: { email: values.email, urlTemplate, registrationData },
          });
          navigate("/valkommen");
          setSubmitting(false);
        }}
      >
        {(props) => {
          const { errors, setFieldTouched } = props;
          i18n.on("languageChanged", () => {
            Object.keys(errors).forEach((fieldName) => {
              setFieldTouched(fieldName);
            });
          });
          return (
            <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <MyTextInput
                label={t("signupform.firstname")}
                name="firstname"
                type="text"
                autoFocus={true}
              />
              <MyTextInput
                label={t("signupform.lastname")}
                name="lastname"
                type="text"
              />
              <MyTextInput
                label={t("signupform.email")}
                name="email"
                type="email"
              />

              <SearchSelect
                items={cities}
                name={"cities"}
                translationKey={"signupform.cities"}
              />

              <SearchSelect
                items={subjects}
                name={"subjects"}
                translationKey={"signupform.subjects"}
              />
              <MultiSelect
                label={t("signupform.schooltype")}
                name="school_types"
              >
                <OptionMapper options={schoolTypes} />
              </MultiSelect>

              <Select label={t("signupform.education.label")} name="education">
                <option value="">
                  {t("signupform.education.placeholder")}
                </option>
                <option value="3">{t("signupform.education.l3")}</option>
                <option value="4">{t("signupform.education.l4")}</option>
                <option value="5">{t("signupform.education.l5")}</option>
              </Select>
              <Checkbox name="license">{t("signupform.license")}</Checkbox>
              <Checkbox name="terms">{t("signupform.terms")}</Checkbox>
              <button
                type="submit"
                disabled={loading}
                className="shadow bg-teal hover:bg-teal focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
              >
                {t("signupform.submit")}
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SignupForm;

const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="mb-4">
      <label
        htmlFor={props.id || props.name}
        className="block text-gray-700 font-bold mb-1 md:mb-0 pr-4"
      >
        {label}
      </label>
      <input
        className=" bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-teal"
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="text-orange-400">{meta.error}</div>
      ) : null}
    </div>
  );
};
const Select = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="mb-4">
      <label
        htmlFor={props.id || props.name}
        className="block text-gray-700 font-bold mb-1 md:mb-0 pr-4"
      >
        {label}
      </label>
      <select
        className=" bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-teal"
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="block text-orange-400">{meta.error}</div>
      ) : null}
    </div>
  );
};
const MultiSelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="mb-4">
      <label
        htmlFor={props.id || props.name}
        className="block text-gray-700 font-bold mb-1 md:mb-0 pr-4"
      >
        {label}
      </label>
      <select
        className=" bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-teal"
        {...field}
        {...props}
        multiple={true}
      />
      {meta.touched && meta.error ? (
        <div className="block text-orange-400">{meta.error}</div>
      ) : null}
    </div>
  );
};
const Checkbox = ({ children, ...props }) => {
  // We need to tell useField what type of input this is
  // since React treats radios and checkboxes differently
  // than inputs/select/textarea.
  const [field, meta] = useField({ ...props, type: "checkbox" });
  return (
    <div className="mb-4">
      <label className="block text-gray-700 font-bold mb-1 md:mb-0 pr-4">
        <input
          type="checkbox"
          {...field}
          {...props}
          className="m-4 text-teal-800  checked:bg-teal"
        />
        {children}
      </label>
      {meta.touched && meta.error ? (
        <div className="text-orange-400">{meta.error}</div>
      ) : null}
    </div>
  );
};

function OptionMapper({ options }) {
  if (options) {
    return (
      <>
        {options.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </>
    );
  }
}

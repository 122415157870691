const translation = {
  languages: {
    en: "EN",
    sv: "SV",
  },
  common: {
    app_name: "undervisare.se",
    about: "Om företaget",
    profile: "profilsida",
    schools: "skolor",
    candidates: "undervisare",
    joblist: "uppdrag",
    signup: "registrera",
    error: "Du behöver registrera dig för att se det här innehållet.",
  },
  home_page: {
    greeting: "Hej {{name}}!",
    log_in_instruction: "Logga in med din emailadress:",
    log_in_button: "logga in",
    log_in_link_sent_to:
      "En login-länk har skickats till {{loginLinkSentTo}}. Vänligen kontrollera din inbox.",
    log_out_button: "Logga ut",
    welcome: "Du är inloggad vad fett",
    signup_button: "Anmäl dig här",
    hero_text:
      "Börja undervisa! Här på undervisare.se hittar du uppdrag på Sveriges skolor. Anmäl dig nu och kom igång direkt.",
    hero_registered:
      "Välkommen tillbaka, nu kan du leta nya uppdrag eller fixa din profil.",
    usp_1_head: "Uppdrag på dina villkor",
    usp_1: "Du väljer själv när, var och hur du vill arbeta.",
    usp_2_head: "Ta vara på din kunskap som undervisare",
    usp_2: "Var med och undervisa Sveriges barn och ungdomar",
    usp_3_head: "Inga mellanhänder",
    usp_3: "Du arbetar direkt för skolan, inga dyra mellanhänder",
    desc:
      "Undervisare.se är en tjänst för dig som vill undervisa. Vi kopplar samman skolor med kunniga undervisare.",
    desc_2: `I Sverige råder det lärarbrist, samtidigt som det finns tusentals personer som kan och vill undervisa. 
      Vi vill hjälpa alla undervisare och alla skolor med en tjänst där de kan hitta varandra!`,
  },
  about_page: {
    heading: "Mer om undervisare.se",
    about_txt: `Det började med att barnens skola hade svårt att hitta vikarie när läraren var sjuk. Det måste finnas duktiga människor som kan undervisa? Varför inte använda digitala redskap för att sammanföra duktiga människor som vill undervisa direkt med skolan? Så föddes tanken med undervisare.se.`,
    solution: `Vi vill bygga en plats där duktiga
    undervisare kan hitta uppdrag på svenska skolor. Det kan vara kortare
    eller längre uppdrag där du och skolan kan lära känna varandra. Eller
    ett sätt för dig med fackkompetens att prova på läraryrket. Den
    digitala tekniken gör att undervisaren och skolan kan mötas direkt,
    utan inblandning av konsulter. Registrera dig på undervisare.se idag
    och välkommen att undervisa Sveriges ungdomar!`,
    about_contact: `Teamet bakom undervisare.se och företaget Behoover AB har byggt
    digitala tjänster under många år, och arbetat som undervisare i
    näringsliv, idrott och kommunal förvaltning. Vi finns på sociala
    medier - länkar längst ner på sidan!`,
  },
  profile_page: {
    email: "{{emailAddress}}",
    levelOfEducation: "Högsta nivå av utbildning:",
    isQualifiedTeacher: "Kvalificerad lärare",
    isSubstituteTeacher: "Vikarie",
    yes: "Ja",
    no: "Nej",
    lastPoliceRegister: "Senaste utrag från belastningsregistret",
    noPoliceRegister: "Lägg till ett datum",
  },
  cookie_page: {
    consent_text:
      "Vi använder cookies för att säkerställa att webbplatserna fungerar, för analys av hur sidorna används samt för att erbjuda relevant annonsering via andra webbplatser, inklusive sociala medier. ",
    link: "Läs mer om cookies.",
    page_content:
      "<p><strong>Våra kakor</strong></p><p>På undervisare.se används cookies och liknande tekniker för att förbättra din upplevelse.</p><br /><p>En cookie är en textfil som lagras tillfälligt eller tillsvidare på datorn när du besöker en webbplats och hjälper webbplatsen att göra personliga inställningar.</p><br /><p>De cookies vi använder förbättrar de tjänster vi erbjuder och upplevelsen på undervisare.se.</p><br /><p>Undervisare använder sig av två olika typer av cookies.Den ena typen är en tillfällig cookie, en sessionscookie, som används under tiden du är inne på webbplatsen för att särskilja dig från andra besökare och upphör när du stänger din webbläsare.Den andra typen är en permanent cookie som ligger kvar på din dator tills utgångsdatum passerar eller du själv väljer att ta bort den.</p><br /><p>Du kan själv ställa in vilka cookies som är tillåtna i din webbläsare.Gå till inställningarna för din webbläsare för att lära dig mer om hur du justerar inställningarna för cookies.Du kan även visa de cookies som sparas på en webbplats.Tänk på att vissa av våra tjänster inte fungerar om du blockerar eller raderar cookies.</p><br /><p>Läs mer om cookies på post- och telestyrelsens webbsida: </p>",
  },
  joblist_page: {
    content: "Aktuella uppdrag",
  },
  signupform: {
    firstname: "Vad heter du i förnamn?",
    lastname: "Vad heter du i efternamn?",
    email: "Email där vi kan nå dig?",
    phoneNumber: "Vad är ditt telefonnummer?",
    cities: {
      label: "I vilken/vilka kommuner vill du undervisa?",
      type: "Kommun",
      available: "Tillgängliga kommuner",
      selected: "Valda kommuner",
      search: "Sök kommuner",
    },

    schooltype: "På vilken typ av skola?",
    subjects: {
      label: "I vilka ämnen?",
      type: "ämne",
      available: "Tillgängliga ämnen",
      selected: "Valda ämnen",
      search: "Sök ämnen",
    },
    education: {
      label: "Vilken (högsta) utbildning har du?",
      placeholder: "välj utbildning",
      l3: "grundskola",
      l4: "gymnasium",
      l5: "universitet/högskola",
    },
    license: "Jag är legitimerad/behörig.",
    terms:
      "Jag godkänner att undervisare.se sparar uppgifterna för att kunna kontakta mig om uppdrag (genom Behoover AB).",
    submit: "spara",
    validation: {
      max_text: "Du kan använda max 20 bokstäver!",
      req_fname: "gärna ett förnamn",
      req_lname: "gärna också ett efternamn",
      val_mail: "Det verkar inte vara en mailadress",
      req_mail: "mailadress så vi kan nå dig",
      req_phonenumber: "Lägg till ett telefonnummer som vi kan ringa dig på",
      val_phoneNumber:
        "Det där ser inte ut som ett telefonnummer. 🤔 Har du skrivit in det rätt?",
      cities: "välj minst en kommun",
      school_types: "välj minst en skolform",
      subjects: "välj minst ett ämne",
      education: "välj gärna en utbildningsform",
      accept: "om du godkänner villkoren kan vi förmedla uppdrag",
    },
  },
  welcome: {
    heading: "Välkommen till undervisare.se!",
    message:
      "Vi har skickat en länk till din emailadress så att du kan logga in.",
  },
  footer: {
    text: "© 2021 - undervisare.se / Behoover AB",
  },
  components: {
    language_picker: {
      label: "Språk",
    },
    job_list: {
      headline: "plats för lämplig text",
      desc: "Beskrivning",
      title: "Rubrik",
      starting_date: "Från",
      end_date: "Till",
    },
    job_listing: {
      desc: "Beskrivning",
      starting_date: "Från",
      end_date: "Till",
      subjects: "Ämnen:",
      school_name: "Skolans namn:",
      location_city: "Stad/Ort:",
      time_span: "Tidsspann: ",
      from: "Från",
      to: "Till",
      contact_name: "Kontaktperson: ",
      contact_phone: "Telefonnummer: ",
      contact_email: "Mejladress: ",
      show_contact_details: "Visa kontaktuppgifter",
      hide_contact_details: "Dölj kontaktuppgifter",
      contact_for_more: "Kontakta skolan för mer information",
      no_contact_info: "Ingen kontaktinformation tillgänglig för denna skola",
    },
    user_list: {
      school_types: "skolformer:",
      subjects: "ämnen:",
    },
    admin_user: {
      is_looking_for_assignments: "Letar efter uppdrag",
      is_short_notice_ready: "Redo att jobba på kort varsel",
    },
    school_list: {
      dummy: "email",
      filter_label_muni: "Välj kommun:",
      filter_default: "välj här",
      filter_label_search: "Sök på skolans namn:",
      th_name: "namn",
      th_principal: "rektor",
      th_phone: "telefon",
      th_email: "email",
      th_site: "hemsida",
      th_zip: "postnr, ort",
    },
    file_upload: {
      label: "Byt profilbild: ",
    },
  },
};

export { translation };

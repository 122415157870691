import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { useTranslation } from "react-i18next";

const REQUEST_LOGIN_LINK = gql`
  mutation($email: String!, $urlTemplate: String!) {
    requestLoginLink(email: $email, urlTemplate: $urlTemplate) {
      success
      message
    }
  }
`;

export default function EmailLoginForm() {
  const { t } = useTranslation();
  const [loginLinkSentTo, setLoginLinkSentTo] = useState(null);
  const [requestLoginLink] = useMutation(REQUEST_LOGIN_LINK);

  let input;
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const urlTemplate =
          window.location.protocol +
          "//" +
          window.location.host +
          "/verifylogin/{{code}}";
        requestLoginLink({ variables: { email: input.value, urlTemplate } });
        setLoginLinkSentTo(input.value);
      }}
      className="bg-white rounded p-4 sm:p-4 mb-4"
    >
      <div className="my-2 text-gray-700">
        {t("home_page.log_in_instruction")}
      </div>
      <p>
        <input
          className=" bg-gray-200 appearance-none border-2 border-gray-200 rounded p-2 sm:p-4 sm:w-1/3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-teal"
          type="email"
          name="email"
          ref={(node) => {
            input = node;
          }}
        ></input>
        <button className="ml-2 shadow bg-teal hover:bg-teal focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded">
          {t("home_page.log_in_button")}
        </button>
      </p>
      {loginLinkSentTo && (
        <div className="my-2 text-gray-700">
          {t("home_page.log_in_link_sent_to", { loginLinkSentTo })}
        </div>
      )}
    </form>
  );
}

/**
 * To find out how language switching works, examine the LanguagePicker component.
 */

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { translation as translation_en } from "./translation_en";
import { translation as translation_sv } from "./translation_sv";

const resources = {
  en: {
    translation: translation_en,
  },
  sv: {
    translation: translation_sv,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    detection: {
      caches: ["localStorage", "cookie"],
    },
  });

export default i18n;

import React from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import klassrum from "../img/klassrum.jpg";
import undervisare from "../img/undervisare.png";
import teacher4 from "../img/teacher4.jpg";
import useSession from "graphql/custom-hooks/useSession";

export default function Root() {
  const [{ user }] = useSession();
  const { t } = useTranslation();
  if (user) {
    return (
      <div className="mt-12 w-full">
        <div className="pt-2 pb-8 w-3/4 ml-auto mr-auto">
          <p className="text-center font-noto text-xl sm:text-3xl text-gray-700">
            {t("home_page.hero_registered")}
          </p>
        </div>
        <img src={teacher4} alt="bild" className="ml-auto mr-auto lg:hidden" />
      </div>
    );
  }
  return (
    <>
      <div className="mt-12 w-full">
        <img src={undervisare} alt="bild" className="sm:h-32 ml-auto mr-auto" />
        <div className="p-4 w-3/4 ml-auto mr-auto">
          <p className="text-center font-noto text-xl sm:text-3xl text-gray-700">
            {t("home_page.hero_text")}
          </p>
        </div>
        <div className="flex flex-row justify-center py-10">
          <Link to="/registrera">
            <button className="bg-teal hover:bg-teal text-white font-bold font-noto py-4 px-8 rounded">
              {t("home_page.signup_button")}
            </button>
          </Link>
        </div>
      </div>
      <div className="bg-cover" style={{ backgroundImage: `url(${klassrum})` }}>
        <div className="sm:flex sm:flex-row justify-center font-noto p-4 sm:px-20 sm:py-32 text-white">
          <div className="px-12 text-center sm:w-1/3">
            <div className="text-4xl">1.</div>
            <div className="text-xl">{t("home_page.usp_1_head")}</div>
            <div>{t("home_page.usp_1")}</div>
          </div>
          <div className="px-12 text-center sm:w-1/3">
            <div className="text-4xl">2.</div>
            <div className="text-xl">{t("home_page.usp_2_head")}</div>
            <div>{t("home_page.usp_2")}</div>
          </div>
          <div className="px-12 text-center sm:w-1/3">
            <div className="text-4xl">3.</div>
            <div className="text-xl">{t("home_page.usp_3_head")}</div>
            <div>{t("home_page.usp_3")}</div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="p-4 w-4/6 ml-auto mr-auto">
          <p className="text-center font-noto text-xl text-gray-700 py-4">
            {t("home_page.desc")}
          </p>
          <p className="text-center font-noto text-xl text-gray-700 py-4">
            {t("home_page.desc_2")}
          </p>{" "}
        </div>
        <div className="flex flex-row justify-center pt-6 pb-12">
          <Link to="/registrera">
            <button className="bg-teal hover:bg-teal text-white font-bold font-noto py-4 px-8 rounded">
              {t("home_page.signup_button")}
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}

import React from "react";
import { Router, globalHistory } from "@reach/router";
import { QueryParamProvider } from "use-query-params";
import VerifyLogin from "./VerifyLogin";
import Root from "./Root";
import About from "./About";
import Profile from "./Profile/Profile";
import UserList from "./UserList";
import SchoolListProvider from "./SchoolListProvider";
import JobList from "./JobList";
import SignupForm from "./SignupForm";
import EmailLoginForm from "./EmailLoginForm";
import CookiePage from "./CookiePage";
import ProofsOfConcept from "./ProofsOfConcept";
import Admin from "./Admin/Admin";
import AdminUsers from "./Admin/AdminUsers";
import AdminUser from "./Admin/AdminUser";
import JobsProofOfConcept from "./JobsProofOfConcept";
import Welcome from "./Welcome";
import JobListing from "./JobListing";
import ProfileForm from "./Profile/ProfileForm";

import dotProp from "dot-prop";

export default function Routes({ session }) {
  const isSuperAdmin = dotProp.get(session, "user.isSuperAdmin", false);
  return (
    <div>
      {/*
        Focus management has been turned off (primary={false}) to prevent the scrolling down
        to the main content. See also: https://stackoverflow.com/q/53058110/2131339
      */}
      <Router primary={false}>
        <QueryParamProvider {...{ path: "/" }} reachHistory={globalHistory}>
          <VerifyLogin path="/verifylogin/:code" />
          <Root path="/" />
          <About path="/om-undervisare" />
          <Profile session={session} path="/profil" />
          <ProfileForm session={session} path={"/profil/redigera"} />
          <UserList path="/undervisare" />
          <SchoolListProvider session={session} path="/skolor" />
          <JobList path="/uppdrag" />
          <JobListing path="/uppdrag/:id" />
          <SignupForm path="/registrera" />
          <EmailLoginForm path="/login" />
          <ProofsOfConcept path="/poc" />
          <JobsProofOfConcept path="/poc/jobs" />
          <JobsProofOfConcept path="/jobs-poc" />
          <CookiePage path="/cookies" />
          <Welcome path="/valkommen" />
          {isSuperAdmin && (
            <>
              <Admin path="/admin" />
              <AdminUsers path="/admin/users" />
              <AdminUser path="/admin/users/:userId" />
            </>
          )}
        </QueryParamProvider>
      </Router>
    </div>
  );
}

import React from "react";
import EmailLoginForm from "./EmailLoginForm";

export default function LoggedOut() {
  return (
    <>
      <EmailLoginForm />
    </>
  );
}

import { Formik, Field, useField } from "formik";
import { useEffect, useRef, useState } from "react";
import React from "react";
import "./SearchSelect.css";
import "tailwind.css";
import _ from "lodash";
import { useTranslation } from "react-i18next";

export default function SearchSelect({ items, name, translationKey }) {
  const [{ value }, , { setValue }] = useField(name);
  const setValueRef = useRef(setValue);
  const valueRef = useRef(value);

  const [selectedItems, setSelectedItems] = useState([]);
  const [unselectedItems, setUnselectedItems] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const { t: translation } = useTranslation();
  /*To make the component reusable with many different fields, a translation key is passed as
  a prop. The key is simply the path in the translation file that pertains to the current field.
  * */
  const t = (key) => {
    return translation(`${translationKey}.${key}`);
  };
  const selectItem = (id) => {
    let indexOfItem = unselectedItems.findIndex((it) => it.id === id);
    if (indexOfItem !== -1) {
      let foundItem = unselectedItems[indexOfItem];
      let newIndex = selectedItems.findIndex(
        (it) => it.originalIndex > foundItem.originalIndex
      );
      let newSelectedItems = [...selectedItems];
      newSelectedItems.splice(newIndex, 0, foundItem);
      setSelectedItems(newSelectedItems);
      let newUnselectedItems = [...unselectedItems];
      newUnselectedItems.splice(indexOfItem, 1);
      setUnselectedItems(newUnselectedItems);
    }
  };
  const unselectItem = (id) => {
    let indexOfItem = selectedItems.findIndex((it) => it.id === id);
    if (indexOfItem !== -1) {
      let foundItem = selectedItems[indexOfItem];
      let newIndex = unselectedItems.findIndex(
        (it) => it.originalIndex > foundItem.originalIndex
      );
      let newUnselectedItems = [...unselectedItems];
      newUnselectedItems.splice(newIndex, 0, foundItem);
      setUnselectedItems(newUnselectedItems);
      let newSelectedItems = [...selectedItems];
      newSelectedItems.splice(indexOfItem, 1);
      setSelectedItems(newSelectedItems);
    }
  };
  useEffect(() => {
    const allItems = items.map((item, i) => {
      return { ...item, originalIndex: i };
    });
    const [initiallyUnselected, initiallySelected] = _.partition(
      allItems,
      (x) => !valueRef.current.find((y) => y.id === x.id)
    );
    setSelectedItems(initiallySelected);
    setUnselectedItems(initiallyUnselected);
  }, [items, valueRef]);
  useEffect(() => {
    setValueRef.current(selectedItems);
  }, [setValueRef, selectedItems]);

  return (
    <>
      <div>
        <div
          className={"grid grid-cols-1 lg:grid-cols-2 lg:w-full xl:w-3/4 gap-4"}
        >
          <div className={"grid grid-cols-1 grid-rows-4 md:grid-rows-5 gap-0"}>
            <div className={"grid grid-cols-2 items-center"}>
              <div className={"mr-5"}>{t("label")}</div>
              <Search
                items={unselectedItems}
                setSearchResults={setSearchResults}
                placeholder={t("search")}
              />
            </div>
            <div
              className={
                "h-40 overflow-y-scroll border border-grey rounded p-5 my-1 lg:my-5 lg:w-full row-span-4 "
              }
            >
              {searchResults.map((item) => {
                if (item === null) {
                  return null;
                }
                return (
                  <SelectableItem
                    item={item}
                    selectionAction={selectItem}
                    selected={false}
                    key={`res_${item.id}`}
                  />
                );
              })}
            </div>
          </div>
          <div className={"grid grid-cols-1 grid-rows-4 md:grid-rows-5 gap-0"}>
            <div className={"p-2 grid items-center"}>
              <div
                className={"block text-gray-700 font-bold mb-1 lg:mb-0 pr-4 "}
              >
                {t("selected")}
              </div>
            </div>
            <div
              className={
                "selected-box h-40 overflow-y-scroll border border-grey rounded p-5 my-1 lg:my-5 lg:w-full row-span-3 md:row-span-4"
              }
            >
              {selectedItems.map((item) => {
                if (item === null) {
                  return null;
                }
                return (
                  <SelectableItem
                    item={item}
                    selectionAction={unselectItem}
                    selected={true}
                    key={`sel_${item.id}`}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function SelectableItem({ item, selectionAction, selected }) {
  return (
    <>
      <div
        className={`${"flex flex-row flex-nowrap justify-between my-1.5"} ${
          selected ? "selected" : "unselected"
        }`}
      >
        {item.name}
        <button
          type={"button"}
          className={
            "rounded-lg bg-teal py-2 md:py-0.5 px-7 md:px-5 text-white text-md shadow"
          }
          onClick={() => {
            selectionAction(item.id);
          }}
        >
          {selected ? "-" : "+"}
        </button>
      </div>
    </>
  );
}

function Search({ items, setSearchResults, placeholder }) {
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setSearchResults(
      items.filter((s) => s.name.toLowerCase().match(searchTerm.toLowerCase()))
    );
  }, [items, searchTerm, setSearchResults]);

  return (
    <>
      <Formik initialValues={{ term: "" }} onSubmit={() => {}}>
        <Field
          className={"bg-gray-200 p-2 rounded"}
          name={"term"}
          as={"input"}
          placeholder={placeholder}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Formik>
    </>
  );
}

import React from "react";
import { useQuery } from "@apollo/client";
import { ALL_USERS } from "../graphql/queries";
import { useTranslation } from "react-i18next";
import Heading from "./Heading";
import NotFound from "./NotFound";
import useSession from "graphql/custom-hooks/useSession";

export default function UserList() {
  const [{ user }] = useSession();
  //Is there a user, is the user Sub - set variable for gql
  const isSub = user ? user.isSubstituteTeacher : false;
  const { data, loading, error } = useQuery(ALL_USERS, {
    variables: { isSubstituteTeacher: isSub },
  });
  if (loading) return <div>Loading...</div>;
  if (error) return <NotFound />;
  if (!data) return <NotFound />;

  const { users } = data;
  if (!user) return <NotFound />;
  return (
    <>
      <Heading transkey="common.candidates" />
      <div className="p-2 text-sm md:text-base text-gray-700">
        {users.map((u) => (
          <User key={u.id} user={u} />
        ))}
      </div>
    </>
  );
}
function User({ user }) {
  const { t } = useTranslation();
  return (
    <div className="py-2 shadow">
      <div className="flex flex-row items-center">
        {user.profilePicture ? (
          <span className="p-2">
            <img
              className="h-10 w-10 rounded-full"
              src={user.profilePicture}
              alt="avatar"
            />
          </span>
        ) : (
          <span className="p-2 m-2 rounded-full bg-gray-300">img</span>
        )}
        <div>
          <div className="font-semibold">name: not displayed</div>
          <div>email: not displayed</div>
        </div>
      </div>
      <div className="py-1 flex flex-wrap flex-row items-center">
        <span className="mx-2 font-bold">
          {t("components.user_list.school_types")}
        </span>
        {user.wantedSchoolTypes.map((schoolType) => (
          <div
            key={schoolType.id}
            className="bg-gray-200 my-1 py-1 px-2 rounded-full"
          >
            {schoolType.name}
          </div>
        ))}
      </div>
      <div className="py-1 flex flex-wrap flex-row items-center">
        <span className="mx-2 font-bold">
          {t("components.user_list.subjects")}
        </span>
        {user.wantedSubjects.map((subject) => (
          <div
            key={subject.id}
            className="bg-gray-200 my-1 py-1 px-2 rounded-full"
          >
            {subject.name}
          </div>
        ))}
      </div>
    </div>
  );
}

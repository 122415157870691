import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_USER } from "../graphql/mutations";

export function UserSwitch({ on, off, initialState, fieldName }) {
  const [isOn, toggle] = useState(initialState);
  const [callableMutation] = useMutation(UPDATE_USER);
  return (
    <>
      <div
        className={`mx-0 inline rounded-full h-7 w-14 flex items-center cursor-pointer ${
          isOn ? "bg-teal justify-end" : "bg-gray-200 justify-start"
        }`}
        onClick={() => {
          callableMutation(isOn ? off : on).then((r) => {
            toggle(r.data.updateUser.user[fieldName]);
          });
        }}
      >
        <div
          className={
            "rounded-full h-4/5 w-2/5 inline-block bg-white z-1/5 m-1 shadow-md"
          }
        />
      </div>
    </>
  );
}

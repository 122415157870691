import React, { useState } from "react";
import LogOutForm from "./LogOutForm";
import LoggedOut from "./LoggedOut";
import { useTranslation } from "react-i18next";
import LanguagePicker from "./LanguagePicker";
import { Link } from "@reach/router";
import logoWhite from "../img/uv_logo_white.png";

export default function Header({ session }) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { user } = session;

  function UserDropdownWide() {
    return (
      <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
        <div
          className="py-1 rounded-md bg-white shadow-xs"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu"
        >
          <Link
            to="/profil"
            className="block px-2 py-2 text-gray-700 hover:bg-gray-100"
          >
            {t("common.profile")}
          </Link>
          {user !== null && <LogOutForm />}
          {user == null && <LoggedOut />}
        </div>
      </div>
    );
  }

  function UserMenu() {
    return (
      <div className="hidden md:contents">
        <div className="px-3 py-2 m-2">
          <div>
            <button
              className="max-w-xs text-sm rounded-full text-white focus:outline-none focus:shadow-solid"
              aria-label="User menu"
              aria-haspopup="true"
              onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
            >
              {user.profilePicture !== null ? (
                <img
                  className="h-8 w-8 rounded-full text-white"
                  src={user.profilePicture}
                  alt="profile img"
                />
              ) : (
                <span className="p-2 bg-gray-200 text-gray-700 rounded-full">
                  img
                </span>
              )}
            </button>
          </div>
          {isOpen && <UserDropdownWide />}
        </div>
      </div>
    );
  }

  function MobileMenu() {
    return (
      <div className={isOpen ? "block md:hidden lg:hidden" : "hidden"}>
        <div className="p-2 space-y-1 sm:px-3">
          <Link
            to="/undervisare"
            className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-teal focus:outline-none focus:text-white focus:bg-teal"
          >
            {t("common.candidates")}
          </Link>
          <Link
            to="/uppdrag"
            className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-teal focus:outline-none focus:text-white focus:bg-teal"
          >
            {t("common.joblist")}
          </Link>
          <Link
            to="/skolor"
            className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-teal focus:outline-none focus:text-white focus:bg-teal"
          >
            {t("common.schools")}
          </Link>
          <Link
            to="/om-undervisare"
            className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-teal focus:outline-none focus:text-white focus:bg-teal"
          >
            {t("common.about")}
          </Link>
          {user == null && (
            <>
              <Link
                to="/registrera"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-teal focus:outline-none focus:text-white focus:bg-teal"
              >
                {t("common.signup")}
              </Link>
              <Link
                to="/login"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-teal focus:outline-none focus:text-white focus:bg-teal"
              >
                {t("home_page.log_in_button")}
              </Link>
            </>
          )}
        </div>
        {/* ändra så att det finns två varianter, med eller utan användare*/}
        {user && (
          <div className="pt-4 pb-3 border-t border-gray-700">
            <div className="flex items-center px-5 space-x-3">
              <div className="flex-shrink-0">
                {user.profilePicture !== null ? (
                  <img
                    className="h-8 w-8 rounded-full"
                    src={user.profilePicture}
                    alt="user avatar"
                  />
                ) : (
                  <span className="p-2 bg-gray-200 text-gray-700 rounded-full">
                    img
                  </span>
                )}
              </div>
              <div className="space-y-1">
                <div className="text-base font-medium leading-none text-white">
                  {user.name}
                </div>
                <div className="text-sm font-medium leading-none text-gray-400">
                  {user.emailAddress}
                </div>
              </div>
            </div>
            <div className="mt-3 px-2 space-y-1">
              <Link
                to="/profil"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-teal focus:outline-none focus:text-white focus:bg-teal"
                role="menuitem"
              >
                {t("common.profile")}
              </Link>
              <LogOutForm
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-teal focus:outline-none focus:text-white focus:bg-teal"
                role="menuitem"
              />
            </div>
          </div>
        )}
      </div>
    );
  }
  //adding Tailwind classes for responsive header/navbar
  return (
    <nav className="bg-teal">
      <div className="mx-auto px-4 sm:px-4 lg:px-8">
        <div className="flex items-center justify-between h-20">
          <div className="flex items-center">
            <div className="flex-shrink-0 py-2 m-2">
              <Link to="/">
                <img
                  src={logoWhite}
                  alt="undervisare.se"
                  className="w-40 -mt-3"
                />
              </Link>
            </div>
            <div className="hidden md:contents md:text-sm lg:text-base">
              <Link
                to="/undervisare"
                className="px-2 py-2 m-2 rounded-md text-gray-300 hover:text-white hover:bg-teal focus:outline-none focus:text-white focus:bg-teal"
              >
                {t("common.candidates")}
              </Link>
              <Link
                to="/uppdrag"
                className="px-2 py-2 m-2 rounded-md text-gray-300 hover:text-white hover:bg-teal focus:outline-none focus:text-white focus:bg-teal"
              >
                {t("common.joblist")}
              </Link>
              <Link
                to="/skolor"
                className="px-2 py-2 m-2 rounded-md text-gray-300 hover:text-white hover:bg-teal focus:outline-none focus:text-white focus:bg-teal"
              >
                {t("common.schools")}
              </Link>
              <Link
                to="/om-undervisare"
                className="px-2 py-2 m-2 rounded-md text-gray-300 hover:text-white hover:bg-teal focus:outline-none focus:text-white focus:bg-teal"
              >
                {t("common.about")}
              </Link>
              {user == null && (
                <Link
                  to="/registrera"
                  className="px-2 py-2 m-2 rounded-md text-gray-300 hover:text-white hover:bg-teal focus:outline-none focus:text-white focus:bg-teal"
                >
                  {t("common.signup")}
                </Link>
              )}
            </div>
          </div>
          <div className="flex flex-row ml-auto align-right ">
            <div className="flex md:text-sm lg:text-base">
              <LanguagePicker />
              {user == null && (
                <Link to="/login">
                  <button className="hidden md:flex border-2 border-gray-300 bg-teal text-white rounded-md md:text-sm lg:text-base md:px-2 lg:px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-teal focus:outline-none focus:shadow-outline">
                    {t("home_page.log_in_button")}
                  </button>
                </Link>
              )}
            </div>
          </div>
          {user !== null && <UserMenu />}
          <div className="md:hidden -mr-2 flex">
            {/* mobile menu button */}
            <button
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-teal focus:outline-none focus:bg-teal focus:text-white"
              onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
            >
              {/* Menu open: "hidden", Menu closed: "block" */}
              <svg
                className="block h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <MobileMenu />
    </nav>
  );
}

import { useQuery } from "@apollo/client";
import dotProp from "dot-prop";
import { SESSION } from "../queries.js";

const useSession = () => {
  const { loading, error, data, refetch } = useQuery(SESSION);
  const session = dotProp.get(data, "session", null);
  return [session, { loading, error, data, refetch }];
};

export default useSession;

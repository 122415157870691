import { gql } from "@apollo/client";

export const ALL_USERS = gql`
  query Users($isSubstituteTeacher: Boolean) {
    users(isSubstituteTeacher: $isSubstituteTeacher) {
      id
      wantedSchoolTypes {
        id
        name
      }
      wantedSubjects {
        id
        name
      }
    }
  }
`;
export const ADMIN_USERS = gql`
  query AdminUsers(
    $isSubstituteTeacher: Boolean
    $requiredSchoolTypeId: ID
    $requiredCityId: ID
    $requiredSubjectIds: [ID]
  ) {
    users(
      isSubstituteTeacher: $isSubstituteTeacher
      requiredCityId: $requiredCityId
      requiredSchoolTypeId: $requiredSchoolTypeId
      requiredSubjectIds: $requiredSubjectIds
    ) {
      id
      emailAddress
      firstName
      lastName
      name
    }
  }
`;

export const ADMIN_USER = gql`
  query AdminUser($userId: ID!) {
    user(id: $userId) {
      emailAddress
      firstName
      phoneNumber
      id
      isLookingForAssignments
      lastPoliceRegister
      isShortNoticeReady
      isQualifiedTeacher
      isSubstituteTeacher
      isSuperAdmin
      lastName
      levelOfEducation
      name
      profilePicture(size: 256)
      wantedCities {
        id
        name
      }
      wantedSchoolTypes {
        id
        name
      }
      wantedSubjects {
        id
        name
      }
    }
  }
`;

export const SESSION = gql`
  query Session {
    session {
      user {
        id
        name
        firstName
        lastName
        emailAddress
        phoneNumber
        isLookingForAssignments
        lastPoliceRegister
        isShortNoticeReady
        isSuperAdmin
        isQualifiedTeacher
        isSubstituteTeacher
        levelOfEducation
        wantedSchoolTypes {
          id
          name
        }
        wantedSubjects {
          id
          name
        }
        wantedCities {
          id
          name
        }
        profilePicture(size: 200)
      }
    }
  }
`;

export const ALL_SCHOOLS = gql`
  query Schools($offset: Int, $limit: Int) {
    schools(offset: $offset, limit: $limit) {
      id
      name
      city {
        id
        name
      }
      email
      phone
      principalsName
      streetAddress
      website
      zip
    }
  }
`;

export const ALL_SCHOOLS_VISITOR = gql`
  query Schools($offset: Int, $limit: Int) {
    schools(offset: $offset, limit: $limit) {
      id
      name
      city {
        name
      }
    }
  }
`;
export const SCHOOLS_SEARCH = gql`
  query SchoolsSearch(
    $cityId: ID
    $countryCode: ID
    $limit: Int
    $searchString: String
  ) {
    schools(
      cityId: $cityId
      countryCode: $countryCode
      limit: $limit
      searchString: $searchString
    ) {
      id
      name
      city {
        id
        name
      }
      email
      phone
      principalsName
      streetAddress
      website
      zip
    }
  }
`;

export const ALL_CITIES = gql`
  query StaticFormData($countryCode: ID) {
    cities(countryCode: $countryCode) {
      id
      name
    }
  }
`;

export const STATIC_FORM_DATA = gql`
  query StaticFormData($countryCode: ID) {
    cities(countryCode: $countryCode) {
      id
      name
    }
    subjects {
      id
      name
    }
    schoolTypes {
      id
      name
    }
  }
`;

export const JOBS = gql`
  query Jobs {
    jobs {
      id
      title
      startDate
      startTime
      endDate
      description
      subjects {
        id
        name
      }
    }
  }
`;

export const JOBS_FULL = gql`
  query Jobs {
    jobs {
      id
      description
      endDate
      endTime
      school {
        id
        city {
          id
          country {
            name
          }
          name
        }
        countrySchoolId
        email
        name
        phone
        principalsName
        streetAddress
        website
        zip
      }
      schoolContactEmail
      schoolContactPhone
      schoolContactName
      schoolNameAndLocation
      startDate
      startTime
      subjects {
        id
        name
      }
      title
    }
  }
`;

export const SINGLE_JOB = gql`
  query Job($id: ID!) {
    job(id: $id) {
      id
      description
      endDate
      endTime
      isDeleted
      schoolContactEmail
      schoolContactPhone
      schoolContactName
      school {
        city {
          name
        }
        name
      }
      startDate
      startTime
      subjects {
        name
      }
      title
    }
  }
`;

import { SINGLE_JOB } from "graphql/queries";
import React, { useState } from "react";
import { useQuery } from "@apollo/client";
//import useSession from "graphql/custom-hooks/useSession";
import NotFound from "./NotFound";
import { useTranslation } from "react-i18next";
import { isEmptyArray } from "formik";

export default function JobListing({ id }) {
  //const [{ user }] = useSession();
  const { data, loading, error } = useQuery(SINGLE_JOB, {
    variables: { id },
  });
  const { t } = useTranslation();
  const [contactDetailsShown, setContactDetailsShown] = useState(false);

  const toggleContactDetails = () => {
    setContactDetailsShown(!contactDetailsShown);
  };

  if (loading) return null;
  if (error) return null;
  if (!data) return <NotFound />;

  const { job } = data;

  return (
    <>
      <div className={"shadow-md w-5/6 mx-auto"}>
        <header className="bg-white">
          <div className="mx-auto px-4 pt-4 lg:px-8 lg:pt-8">
            <h1 className="text-2xl md:text-3xl font-bold leading-tight text-gray-700">
              {job.title}
            </h1>
          </div>
        </header>{" "}
        <div className={"md:grid grid-cols-2 mt-10 mx-10 my-10 pb-10 gap-x-9"}>
          <div className={"col-span-1 grid grid-cols-1 gap-y-1"}>
            {job.school && (
              <>
                {job.school.name !== "null" && (
                  <>
                    <div className={"text-lg font-bold"}>
                      {t("components.job_listing.school_name")}
                    </div>
                    <div className={""}>{job.school.name}</div>
                  </>
                )}

                {job.school.city !== "null" && (
                  <>
                    <div className={"text-lg font-bold"}>
                      {t("components.job_listing.location_city")}
                    </div>
                    <div className={""}>{job.school.city.name}</div>
                  </>
                )}
              </>
            )}

            {!isEmptyArray(job.subjects) && (
              <>
                <div className={"text-lg font-bold"}>
                  {t("components.job_listing.subjects")}
                </div>
                <div className={""}>
                  {!isEmptyArray(job.subjects)
                    ? job.subjects
                    : t("components.job_listing.contact_for_more")}
                </div>
              </>
            )}

            <div className={"text-lg font-bold"}>
              {t("components.job_listing.time_span")}
            </div>
            {job.startDate && (
              <div className={""}>{`${t("components.job_listing.from")} ${
                job.startDate
              }   \xa0\xa0\xa0\xa0  ${
                job.endDate
                  ? `${t("components.job_listing.to")} ${job.endDate}`
                  : ""
              }`}</div>
            )}
          </div>

          <div className={"col-span-1"}>
            <div className={""}>
              <div className={"font-bold text-lg"}>
                {t("components.job_listing.desc")}
              </div>
              <div className={""}>{job.description}</div>
            </div>
          </div>

          {job.schoolContactEmail ||
          job.schoolContactPhone ||
          job.schoolContactName ? (
            <div
              className={
                "cursor-pointer text-center col-span-2 mt-10 text-xl underline"
              }
              onClick={toggleContactDetails}
            >
              {t(
                contactDetailsShown
                  ? "components.job_listing.hide_contact_details"
                  : "components.job_listing.show_contact_details"
              )}
            </div>
          ) : (
            <div className={"text-center col-span-2 mt-10 text-xl"} />
          )}
          {contactDetailsShown && (
            <div
              className={"grid grid-cols-1 lg:grid-cols-3 lg:col-span-2 mt-5"}
            >
              {job.schoolContactEmail && (
                <div className={" "}>
                  <div className={"font-bold text-lg  row-start-4"}>
                    {t("components.job_listing.contact_email")}
                  </div>
                  <div className={""}>{job.schoolContactEmail}</div>
                </div>
              )}
              {job.schoolContactPhone && (
                <div className={""}>
                  <div className={"font-bold text-lg"}>
                    {t("components.job_listing.contact_phone")}
                  </div>
                  <div className={""}>{job.schoolContactPhone}</div>
                </div>
              )}
              {job.schoolContactName && (
                <div className={""}>
                  <div className={"font-bold text-lg"}>
                    {t("components.job_listing.contact_name")}
                  </div>
                  <div className={""}>{job.schoolContactName}</div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

import React from "react";
import { useMutation, gql } from "@apollo/client";
import { useTranslation } from "react-i18next";

const LOG_OUT = gql`
  mutation {
    logOut {
      success
      message
    }
  }
`;

export default function LogOutForm() {
  const { t } = useTranslation();
  const [logOut, { loading }] = useMutation(LOG_OUT);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        logOut({ refetchQueries: ["Session"] });
      }}
    >
      <button
        disabled={loading}
        className="block px-2 py-2 font-medium text-gray-300 sm:text-gray-700 hover:bg-gray-100"
      >
        {t("home_page.log_out_button")}
      </button>
    </form>
  );
}

import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { ALL_CITIES, SCHOOLS_SEARCH } from "../graphql/queries";
import Heading from "./Heading";
import { useTranslation } from "react-i18next";

export default function SchoolListSuperadmin() {
  const [opValue, setOpValue] = useState("");
  const [searchText, setSearchText] = useState("");
  const handleChange = (e) => {
    setOpValue(e.target.value);
  };
  const handleEdit = (e) => {
    setSearchText(e.target.value);
  };
  return (
    <div>
      <Heading transkey="common.schools" />
      <div className="p-2 text-sm text-gray-700 lg:px-6">
        <Filter
          opValue={opValue}
          handleChange={handleChange}
          handleEdit={handleEdit}
        />
        <List muniId={opValue} searchText={searchText} />
      </div>
    </div>
  );
}
const Filter = ({ opValue, handleChange, handleEdit }) => {
  const { t } = useTranslation();
  const { data } = useQuery(ALL_CITIES, {
    variables: { countryCode: "se" },
  });
  if (!data) {
    return null;
  }
  const { cities } = data;
  return (
    <div className="sm:text-base sm:flex sm:flex-row sm:items-baseline m-2">
      <div>
        <label>{t("components.school_list.filter_label_muni")}</label>
        <select
          onChange={handleChange}
          value={opValue}
          className="p-2 w-full mt-2 border-2"
        >
          <option key="dummykey" value="" selected>
            {t("components.school_list.filter_default")}
          </option>
          {cities.map(({ id: cityId, name: cityName }) => (
            <option key={cityId} value={cityId}>
              {cityName}
            </option>
          ))}{" "}
        </select>
      </div>
      <div className="mt-4 sm:mt-0 sm:m-6">
        <label>{t("components.school_list.filter_label_search")}</label>
        <input
          type="text"
          className="mt-2 w-full bg-gray-200  p-2"
          onChange={handleEdit}
        />
      </div>{" "}
    </div>
  );
};
const List = ({ muniId, searchText }) => {
  const { t } = useTranslation();
  const { data } = useQuery(SCHOOLS_SEARCH, {
    variables: {
      cityId: muniId,
      countryCode: "se",
      searchString: searchText,
      limit: 30,
    },
  });
  return (
    <table className="w-full border-collapse m-2">
      <thead>
        <tr className="text-left text-gray-700 font-bold uppercase bg-gray-200">
          <th className="p-3 border border-gray-300 hidden lg:table-cell">
            {t("components.school_list.th_name")}
          </th>
          <th className="p-3 border border-gray-300 hidden lg:table-cell">
            {t("components.school_list.th_principal")}
          </th>
          <th className="p-3 border border-gray-300 hidden lg:table-cell">
            {t("components.school_list.th_phone")}
          </th>
          <th className="p-3 border border-gray-300 hidden lg:table-cell">
            {t("components.school_list.th_email")}
          </th>
          <th className="p-3 border border-gray-300 hidden lg:table-cell">
            {t("components.school_list.th_site")}
          </th>
          <th className="p-3 border border-gray-300 hidden lg:table-cell">
            {t("components.school_list.th_zip")}
          </th>
        </tr>
      </thead>
      <tbody>
        {data &&
          muniId !== "" &&
          data.schools.map((school) => (
            <School key={school.id} school={school} />
          ))}
      </tbody>
    </table>
  );
};

function School({ school }) {
  return (
    <tr className="bg-white lg:hover:bg-gray-100 flex lg:table-row lg:text-base flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-4 lg:mb-0 shadow mt-2">
      <td className="mx-2 w-full lg:w-auto lg:p-2 text-black font-extrabold lg:border lg:border-b block lg:table-cell relative lg:static">
        {school.name}
      </td>
      <td className="mx-2 w-full lg:w-auto lg:p-2 text-gray-800 lg:border lg:border-b block lg:table-cell relative lg:static">
        {school.principalsName}
      </td>
      <td className="mx-2 w-full lg:w-auto lg:p-2 text-gray-800 lg:border lg:border-b block lg:table-cell relative lg:static">
        {school.phone}
      </td>
      <td className="mx-2 w-full lg:w-auto lg:p-2 text-gray-800 lg:border lg:border-b block lg:table-cell relative lg:static">
        <a href={"mailto:" + school.email}>{school.email}</a>
      </td>
      <td className="mx-2 w-full lg:max-w-xs lg:w-auto lg:p-2 text-gray-800 lg:border lg:border-b block lg:table-cell relative lg:static">
        <a href={school.website}>{school.website}</a>
      </td>
      <td className="mx-2 w-full lg:w-auto lg:p-2 text-gray-800 lg:border lg:border-b block lg:table-cell relative lg:static mb-2">
        {school.zip + " "} {school.city.name}
      </td>
    </tr>
  );
}

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./i18n/index.js";
import "./tailwind.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN
    ? process.env.REACT_APP_SENTRY_DSN
    : null,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: createHttpLink({
    uri: process.env.REACT_APP_GQLAPI,
    credentials: "include",
  }),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

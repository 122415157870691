import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "@reach/router";

export default function Cookie() {
  const { t } = useTranslation();
  const [isOk, setIsOk] = React.useState(
    JSON.parse(localStorage.getItem("uv-consent") || true)
  );

  React.useEffect(() => {
    localStorage.setItem("uv-consent", isOk);
  }, [isOk]);

  const onClick = () => {
    setIsOk(false);
  };

  return (
    <>
      {isOk ? (
        <div
          className="bg-teal border border-gray-100 text-white px-4 lg:px-64 py-3 relative"
          role="alert"
        >
          <div className="text-center mr-6">
            {t("cookie_page.consent_text")}
            <Link className="underline" to="/cookies">
              {t("cookie_page.link")}
            </Link>
          </div>
          <div className="absolute top-0 bottom-0 right-0 px-4 py-3">
            <svg
              onClick={onClick}
              className="fill-current h-6 w-6 text-white"
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </div>
        </div>
      ) : null}
    </>
  );
}

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.min.css";
import React from "react";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import dotProp from "dot-prop";

const nullifyZeroDate = (date) => {
  if (date.getTime() === 0) {
    return null;
  } else {
    return date;
  }
};

const DatePickerField = ({ mutation, initialDate, userId, placeholder }) => {
  const [startDate, setStartDate] = useState(
    nullifyZeroDate(initialDate ? new Date(initialDate) : new Date(null))
  );
  const [callableMutation] = useMutation(mutation);

  return (
    <DatePicker
      excludeDateIntervals={[
        { start: new Date(Date.now()), end: new Date(8640000000000000) },
      ]}
      isClearable
      selected={startDate}
      placeholderText={placeholder}
      onChange={(date) => {
        callableMutation({
          variables: {
            input: {
              id: userId,
              lastPoliceRegister: new Date(date),
            },
          },
        }).then((r) => {
          let newDate = new Date(
            dotProp.get(r, "data.updateUser.user.lastPoliceRegister")
          );
          setStartDate(nullifyZeroDate(newDate));
        });
      }}
      className={"bg-gray-200 p-2 w-full sm:w-3/5"}
    />
  );
};

export default DatePickerField;

import React from "react";
import { useQuery } from "@apollo/client";
import { SESSION } from "../graphql/queries";
import Header from "./Header";
import Routes from "./Routes";
import Footer from "./Footer";
import CookieConsent from "./CookieConsent";
import "@fontsource/noto-serif";
import "@fontsource/lato";

export default function App() {
  const { loading, error, data } = useQuery(SESSION);
  if (loading || !data) return null;
  if (error) return <div>Error loading user session.</div>;
  const { session } = data;
  if (!session) return <div>No user session.</div>;
  return (
    <div className="flex flex-col min-h-screen">
      <CookieConsent />
      <Header session={session} />
      <Routes session={session} className="flex-grow" />
      <Footer />
    </div>
  );
}

import React from "react";
import teacher from "../img/teacher.jpg";
import { useTranslation } from "react-i18next";

export default function CorporateTxt() {
  const { t } = useTranslation();
  return (
    <>
      <div className=" text-gray-700 lg:px-4">
        <div className="p-2 m-2 sm:pr-32">
          {t("about_page.about_txt")}
          <br />
          <br />
          <p>{t("about_page.solution")}</p>
        </div>
        <div className="p-8 lg:hidden">
          <img src={teacher} alt="lärare" />
        </div>
        <div className="p-2 m-2 sm:pr-32">{t("about_page.about_contact")}</div>
      </div>
    </>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { CHANGE_LANGUAGE } from "../graphql/mutations";

const availableLanguages = ["sv", "en"];

export default function LanguagePicker() {
  const [changeLanguage] = useMutation(CHANGE_LANGUAGE);
  const { t, i18n } = useTranslation();
  return (
    <>
      <select
        className="appearance-none bg-teal text-gray-300 text-sm mx-4 my-2"
        value={i18n.language}
        onChange={(event) => {
          // Front end
          i18n.changeLanguage(event.target.value);
          // Back end
          changeLanguage({
            variables: { language: event.target.value },
          });
        }}
      >
        {availableLanguages
          .sort((l1, l2) =>
            t(`languages.${l1}`).localeCompare(
              t(`languages.${l2}`),
              i18n.language
            )
          )
          .map((language) => (
            <option key={language} value={language}>
              {t(`languages.${language}`)}
            </option>
          ))}
      </select>
    </>
  );
}

import React from "react";
import { Trans, useTranslation } from "react-i18next";

export default function CookiePage() {
  const { t } = useTranslation();
  return (
    <div className=" text-gray-700 font-noto p-4 md:p-12">
      <Trans key="cookie_page">
        <div>
          {t("cookie_page.page_content")}
          <a href="https://pts.se" className="underline">
            PTS
          </a>
        </div>
      </Trans>
    </div>
  );
}

import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { DateTime } from "luxon";
import DateTimePicker from "react-datetime-picker";
import DatePicker from "react-date-picker";
import keyBy from "lodash.keyby";
import { JOBS, STATIC_FORM_DATA } from "../graphql/queries";
import { CREATE_JOB } from "../graphql/mutations";
import useSession from "graphql/custom-hooks/useSession";

const SCHOOLS_SEARCH = gql`
  query SchoolsSearch(
    $cityId: ID
    $countryCode: ID
    $limit: Int
    $searchString: String
  ) {
    schools(
      cityId: $cityId
      countryCode: $countryCode
      limit: $limit
      searchString: $searchString
    ) {
      id
      city {
        name
      }
      name
    }
  }
`;

export default function JobsProofOfConcept() {
  const [{ user }] = useSession();
  const { data } = useQuery(STATIC_FORM_DATA, {
    variables: { countryCode: "se" },
  });
  if (!data) {
    return null;
  }
  return (
    <>
      <h1>Jobb - ett testskott</h1>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <h2>Skapa jobb</h2>
          <JobCreationForm cities={data.cities} />
        </div>
        <div>
          <h2>Alla jobb</h2>
          {user && <AllJobs />}
          {!user && <p>Du är inte inloggad, så du kan inte se alla jobb.</p>}
        </div>
      </div>
    </>
  );
}

function AllJobs() {
  const { data } = useQuery(JOBS);
  if (!data) {
    return null;
  }
  return <pre>{JSON.stringify(data.jobs, null, 2)}</pre>;
}

function JobCreationForm({ cities }) {
  const now = new Date();
  const [createJob] = useMutation(CREATE_JOB);
  const [selectedCityId, setSelectedCityId] = useState(idOfStockholm(cities));
  const [schoolId, setSchoolId] = useState(null);
  const [schoolName, setSchoolName] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [start, setStart] = useState(now);
  const [endDate, setEndDate] = useState(now);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const keyedCities = keyBy(cities, "id");

  const createInput = () => ({
    description,
    endDate: DateTime.fromJSDate(endDate).toFormat("yyyy-LL-dd"),
    endTime: null,
    schoolContactEmail: email,
    schoolContactPhone: phone,
    schoolContactName: name,
    schoolId,
    schoolNameAndLocation: schoolId
      ? null
      : `${schoolName}, ${keyedCities[selectedCityId].name}`,
    startDate: DateTime.fromJSDate(start).toFormat("yyyy-LL-dd"),
    startTime: DateTime.fromJSDate(start).toFormat("HH:mm:ss"),
    title,
  });

  const handleSubmit = (event) => {
    createJob({
      variables: {
        input: createInput(),
      },
      refetchQueries: ["Jobs"],
    });
    event.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>
          Stad:
          <select
            value={selectedCityId}
            onChange={(event) => setSelectedCityId(event.target.value)}
          >
            {cities.map(({ id: cityId, name: cityName }) => (
              <option key={cityId} value={cityId}>
                {cityName}
              </option>
            ))}
          </select>
        </label>
      </div>
      <label>
        Skola:
        <div>
          {
            <SchoolSelect
              cityId={selectedCityId}
              onChange={({ schoolId, schoolName }) => {
                setSchoolId(schoolId);
                setSchoolName(schoolName);
              }}
            />
          }
        </div>
      </label>
      <div>
        <label>Rubrik: </label>
        <input
          type="text"
          value={title}
          onChange={(event) => setTitle(event.target.value)}
          className="border-2 bg-gray-200"
        />
      </div>
      <div>
        <label>Beskrivning:</label>
        <br />
        <textarea
          value={description}
          onChange={(event) => setDescription(event.target.value)}
          className="border-2 bg-gray-200"
        />
      </div>
      <div>
        <label>Börjar: </label>
        <DateTimePicker onChange={setStart} value={start} />
      </div>
      <div>
        <label>Slutar: </label>
        <DatePicker onChange={setEndDate} value={endDate} />
      </div>
      <div>
        <label>Kontaktperson: </label>
        <input
          type="text"
          name="name"
          value={name}
          onChange={(event) => setName(event.target.value)}
          className="border-2 bg-gray-200"
        />
      </div>
      <div>
        <label>Email: </label>
        <input
          type="email"
          name="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          className="border-2 bg-gray-200"
        />
      </div>
      <div>
        <label>Mobilnummer: </label>
        <input
          type="tel"
          name="phone"
          value={phone}
          onChange={(event) => setPhone(event.target.value)}
          className="border-2 bg-gray-200"
        />
      </div>
      Skickas till createJob om man klickar{" "}
      <button type="submit" className="border-2 bg-gray-200">
        här
      </button>
      :<pre>{JSON.stringify(createInput(), null, 2)}</pre>
    </form>
  );
}

function SchoolSelect({ cityId, onChange }) {
  const [schoolText, setSchoolText] = useState("");
  const [selectedSchoolValue, setSelectedSchoolValue] = useState("raw");
  const { data } = useQuery(SCHOOLS_SEARCH, {
    variables: {
      cityId,
      countryCode: "se",
      searchString: schoolText,
      limit: 3,
    },
  });
  let foundSchools = [];
  if (schoolText.trim() && data && data.schools) {
    foundSchools = data.schools;
  }
  const onSelectSchool = (event) => {
    setSelectedSchoolValue(event.target.value);
    const schoolId = event.target.value === "raw" ? null : event.target.value;
    const schoolName = event.target.value !== "raw" ? null : schoolText;
    onChange({ schoolId, schoolName });
  };
  return (
    <>
      <ul>
        <li>
          <input
            type="radio"
            value="raw"
            name="selectedSchool"
            onChange={onSelectSchool}
            checked={selectedSchoolValue === "raw"}
          />{" "}
          <input
            className="border-2 bg-gray-200"
            type="text"
            value={schoolText}
            onChange={(event) => {
              setSchoolText(event.target.value);
              const schoolId =
                selectedSchoolValue === "raw" ? null : selectedSchoolValue;
              const schoolName =
                selectedSchoolValue !== "raw" ? null : event.target.value;
              onChange({ schoolId, schoolName });
            }}
          />
        </li>
        {foundSchools.map(({ id: schoolId, name: schoolName }) => (
          <li value={schoolId}>
            <input
              type="radio"
              value={schoolId}
              name="selectedSchool"
              onChange={onSelectSchool}
              checked={selectedSchoolValue === schoolId}
            />{" "}
            {schoolName}
          </li>
        ))}
      </ul>
    </>
  );
}

function idOfStockholm(cities) {
  for (const { id, name } of cities) {
    if (name.trim().toLowerCase() === "stockholm") {
      return id;
    }
  }
  return cities[0].id;
}

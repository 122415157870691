import React from "react";
import Heading from "./Heading";
import { useTranslation } from "react-i18next";

export default function Welcome() {
  const { t } = useTranslation();
  return (
    <>
      <Heading transkey="welcome.heading" />
      <div className=" text-gray-700 md:flex md:flex-row lg:px-4">
        <div className="p-2 m-2">{t("welcome.message")}</div>
      </div>
    </>
  );
}

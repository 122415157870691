import React from "react";
import SchoolListVisitor from "./SchoolListVisitor";
import SchoolListSuperadmin from "./SchoolListSuperadmin";
import useSession from "graphql/custom-hooks/useSession";

export default function SchoolListProvider() {
  const [{ user }] = useSession();
  if (user && user.isSuperAdmin) return <SchoolListSuperadmin />;
  return <SchoolListVisitor />;
}

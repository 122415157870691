const translation = {
  languages: {
    en: "EN",
    sv: "SV",
  },
  common: {
    app_name: "undervisare.se",
    about: "About us",
    profile: "Profile",
    schools: "Schools",
    candidates: "Candidates",
    joblist: "Assignments",
    signup: "Register",
    error: "You need to register to see this page.",
  },
  home_page: {
    greeting: "Hi {{name}}!",
    log_in_instruction:
      "To log in, please enter your email address and press OK.",
    log_in_button: "login",
    log_in_link_sent_to:
      "A login link has been sent to {{loginLinkSentTo}}. Please check your inbox.",
    log_out_button: "Log out",
    welcome: "You are logged in, love it!",
    signup_button: "Sign up here",
    hero_text:
      "Start teaching! Undervisare.se helps you find teaching assignments at schools in Sweden. Register today to start this semester.",
    hero_registered:
      "Welcome back, now you are ready to find assignments, or set up your profile!",
    usp_1_head: "Assignments on your terms",
    usp_1: "You decide when, where and how you want to work.",
    usp_2_head: "Make use of your ability to teach",
    usp_2: "Join us teaching the Swedish youth",
    usp_3_head: "No intermediaries",
    usp_3: "You work directly for the school, no expensive intermediaries.",
    desc:
      "Undervisare.se is a service for you who wants to teach. We match schools with capable teachers.",
    desc_2:
      "In Sweden there is a lack of teachers, while at the same time thousands of people both want to and are able to teach. We want to help you and all teachers and schools with a service where you can find eachother.",
  },
  about_page: {
    heading: "About undervisare.se",
    about_txt: `It started with our childrens school having trouble finding substitute teachers when a teacher was ill. There has to be talented people ready to teach? Why not make use of digital tools to connect capable persons wanting to teach directly with the school? This gave birth to undervisare.se.`,
    solution: `We want to build a place where talented educators can find assignments at Swedish schools. It can be longer or shorter assignments where you and the school can get to know eachother. 
    Or a way for you with a specific academic degree to try out the teaching profession. The digital technology makes it possible for the educator and the school to meet directly, without the involvement of consultants.
Register on undervisare.se today and welcome to teach the youth of Sweden!`,
    about_contact: `The team behind undervisare.se and the company Behoover AB has long experience building digital services, and have teached in various settings in industry, sport and public service.
    We are on social media - links in the footer!`,
  },
  profile_page: {
    email: "{{emailAddress}}",
    levelOfEducation: "Level of education",
    isQualifiedTeacher: "Qualified teacher",
    isSubstituteTeacher: "Substitute",
    yes: "Yes",
    no: "No",
    lastPoliceRegister: "Latest police register excerpt",
    noPoliceRegister: "Add a date",
  },
  cookie_page: {
    consent_text:
      "We use cookies to ensure the service is working, to analyse how the service is used, and for advertising partners including social media. ",
    link: "Read more about cookies.",
  },
  joblist_page: {
    content: "Current openings",
  },
  signupform: {
    firstname: "What is your first name?",
    lastname: "What is your last name?",
    email: "An email address where we can contact you?",
    phoneNumber: "What is your phone number?",
    cities: {
      label: "In which municipalities would you like to teach?",
      type: "municipality",
      available: "Available municipalities",
      selected: "Selected municipalities",
      search: "Search municipalities",
    },
    schooltype: "At what type of school?",
    subjects: {
      label: "In which subjects?",
      type: "subject",
      available: "Available subjects",
      selected: "Selected subjects",
      search: "Search subjects",
    },
    education: {
      label: "What is your (highest) level of education?",
      placeholder: "Select a type",
      l3: "Elementary School",
      l4: "High School",
      l5: "University/College",
    },
    license: "I am a licensed teacher in Sweden",
    terms:
      "I approve that undervisare.se stores my information to be able to contact me regarding assignments (through Behoover AB).",
    submit: "save",
    validation: {
      max_text: "Please use max 20 letters",
      req_fname: "your first name, please",
      req_lname: "your last name, please",
      val_mail: "Is that really an email address?",
      req_mail: "Your email so that we can reach you",
      req_phonenumber: "Add a phone number we can call you on",
      val_phoneNumber:
        "That doesn't look like a phone number to me. Did you type it in correctly?",
      cities: "Select at least one municipality",
      school_types: "Select at least one school type",
      subjects: "Select at least one subject",
      education: "Please select your education level",
      accept: "If you accept the conditions we can provide assignments.",
    },
  },
  welcome: {
    heading: "Welcome to undervisare.se!",
    message: "We have sent a login link to your email address.",
  },
  footer: {
    text: "© 2021 - undervisare.se / Behoover AB",
  },
  components: {
    language_picker: {
      label: "Language",
    },
    job_list: {
      headline: "probably some text",
      desc: "Description",
      title: "Title",
      starting_date: "Starting date",
      end_date: "End date",
    },
    job_listing: {
      desc: "Description: ",
      starting_date: "Starting date",
      end_date: "End date",
      subjects: "Subjects:",
      school_name: "School name:",
      location_city: "City:",
      time_span: "Time span: ",
      from: "From",
      to: "Until",
      contact_name: "Contact name: ",
      contact_phone: "Phone number: ",
      contact_email: "E-mail adress: ",
      show_contact_details: "Show contact details",
      hide_contact_details: "Hide contact details",
      contact_for_more: "Contact the school for more information",
      no_contact_info: "No contact information available for this school",
    },
    user_list: {
      school_types: "School Types:",
      subjects: "Subjects:",
    },
    admin_user: {
      is_looking_for_assignments: "Looking for work",
      is_short_notice_ready: "Ready to work on short notice",
    },
    school_list: {
      dummy: "Email etc.",
      filter_label_muni: "Select city:",
      filter_default: "select here",
      filter_label_search: "Search on school name:",
      th_name: "Name",
      th_principal: "Principal",
      th_phone: "Phone",
      th_email: "Email",
      th_site: "Website",
      th_zip: "Zip, City",
    },
    file_upload: {
      label: "Switch profile picture  : ",
    },
  },
};

export { translation };

import React from "react";
import { Link } from "@reach/router";
export default function Admin() {
  return (
    <div className="m-4">
      <h1 className="text-lg">
        <span>Admin</span>
      </h1>
      <ul className="list-disc list-inside">
        <li>
          <Link to="users" className="text-blue-700">
            Users
          </Link>
        </li>
      </ul>
    </div>
  );
}

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const uploadUrl = process.env.REACT_APP_GQLAPI.replace("/graphql", "/upload");

const FileUpload = ({ refetchProfilePicture }) => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [lastError, setLastError] = useState(null);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmission = () => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("profile-picture", selectedFile);
    fetch(uploadUrl, {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    })
      .then((result) => {
        setIsUploading(false);
        setLastError(null);
        refetchProfilePicture();
      })
      .catch((error) => {
        setIsUploading(false);
        setLastError(error);
        console.error("Error uploading profile pic:", error);
      });
  };

  return (
    <div className="w-full text-gray-800 p-6 m-2">
      <label className="font-bold text-gray-800">
        {t("components.file_upload.label")}
      </label>
      <input
        type="file"
        name="profile-picture"
        accept="image/*"
        onChange={changeHandler}
      />
      {(selectedFile || isUploading) && (
        <button
          disabled={isUploading}
          onClick={handleSubmission}
          className="shadow bg-teal hover:bg-teal focus:shadow-outline focus:outline-none text-white font-bold my-2 py-2 px-4 rounded"
        >
          {t("signupform.submit")}
        </button>
      )}
      {isUploading && <span>Uploading ...</span>}
      {lastError && (
        <>
          Error: <pre>{JSON.stringify(lastError, null, 2)}</pre>
        </>
      )}
    </div>
  );
};

export default FileUpload;
